
import { Component, Vue, Watch } from "vue-property-decorator";
import { ProgramPaths } from "@/enum/ProgramPaths";
import { Product } from "@/enum/Product";
import FeedbackDialog from "@/components/dialogs/FeedbackDialog.vue";
import axios from 'axios';
import ChatbotDialog from "@/components/dialogs/ChatbotDialog.vue";
import WhatsappDialog from "@/components/dialogs/WhatsappDialog.vue";

@Component({
  components: {
    ChatbotDialog,
    FeedbackDialog,
    WhatsappDialog
  },
})
export default class MenuBar extends Vue {
  items: any = [];
  chatBotDialog: boolean = false;
  showFeedbackDialog: boolean = false;
  whatsappDialog: boolean = false;

  ofisProItems: Array<any> = [
    {
      id: 1,
      baslikAdi: "Büro Bilgileri",
      route: ProgramPaths.ofispro + "/buro-bilgileri",
      icon: "mdi-bank",
      altbaslik: false,
      altbasliklar: null,
      authorizationKey: "buro-modulu",
    },
    {
      id: 2,
      baslikAdi: "Vekaletnameler",
      route: ProgramPaths.ofispro + "/vekaletler",
      icon: "mdi-file",
      altbaslik: false,
      altbasliklar: null,
      authorizationKey: "vekalet-modulu",
    },
    {
      id: 3,
      baslikAdi: "Raporlar",
      route: ProgramPaths.ofispro + "/raporlar",
      icon: "mdi-file-chart",
      altbaslik: false,
      altbasliklar: null,
      authorizationKey: "rapor-modulu",

    },
    {
      id: 4,
      baslikAdi: "Dilekçeler",
      route: ProgramPaths.ofispro + "/dilekceler",
      icon: "mdi-file-document-edit",
      altbaslik: false,
      altbasliklar: null,
      authorizationKey: "dilekce-modulu",

    },
    //TODO :: ÇALIŞMADIĞI İÇİN KAPATTTIM @sebo
    {
      id: 5,
      baslikAdi: "Kasa",
      route: ProgramPaths.ofispro + "/kasa",
      icon: "mdi-safe-square",
      altbaslik: false,
      altbasliklar: null,
      authorizationKey: "kasa-modulu",

    },/*
    {
      id: 6,
      baslikAdi: "Klasörler",
      route: ProgramPaths.ofispro + "/klasorler",
      icon: "mdi-folder",
      altbaslik: false,
      altbasliklar: null,
      authorizationKey: "klasor-modulu",

    },*/
    {
      id: 7,
      baslikAdi: "Ajanda",
      route: ProgramPaths.ofispro + "/ajanda",
      icon: "mdi-calendar",
      altbaslik: false,
      altbasliklar: null,
      authorizationKey: "ajanda-modulu",

    },

    {
      id: 8,
      baslikAdi: "Kullanıcı İzinleri",
      route: ProgramPaths.ofispro + "/kullanici-izinleri",
      icon: "mdi-atom",
      altbaslik: false,
      altbasliklar: null,
      authorizationKey: "tercih-modulu",
    },
    {
      id: 8,
      baslikAdi: "İşlem Kaydı",
      route: ProgramPaths.ofispro + "/islem-kaydi",
      icon: "mdi-math-log",
      altbaslik: false,
      altbasliklar: null,
      authorizationKey: "tercih-modulu",
    },
    {
      id: 9,
      baslikAdi: "İcraPro Aktar",
      route: ProgramPaths.ofispro + "/icw-aktar",
      icon: "mdi-file-download-outline",
      altbaslik: false,
      altbasliklar: null,
      authorizationKey: 'aktar-modulu'

    },
    {
      id: 10,
      baslikAdi: "Genel İstatistikler",
      route: ProgramPaths.ofispro + "/genel-istatistikler",
      icon: "mdi-chart-bar",
      altbaslik: false,
      authorizationKey: "genel-istatistikler",
      altbasliklar: null
    },
    {
      id: 11,
      baslikAdi: "Uyarılar",
      route: ProgramPaths.ofispro + "/uyarilar",
      icon: "mdi-bell",
      altbaslik: false,
      authorizationKey: "uyarilar",
      altbasliklar: null
    },
  ];

  icraProItems: Array<any> = [
    {
      id: 1,
      baslikAdi: "Takipler",
      route: null,
      icon: "mdi-account-voice",
      authorizationKey: "takip-listesi",
      altbaslik: true,
      altbasliklar: [
        {
          id: 1,
          baslikAdi: "Takip Aç",
          route: ProgramPaths.icrapro + "/takip-ekle",
          icon: "mdi-plus-thick",
          authorizationKey: "takip-ac",
        },
        {
          id: 2,
          baslikAdi: "Takip Listesi",
          route: ProgramPaths.icrapro + "/takipler",
          icon: "mdi-format-list-numbered",
          authorizationKey: "takip-listesi"
        },
      ],
    },
    {
      id: 2,
      baslikAdi: "Tercihler",
      route: ProgramPaths.icrapro + "/tercihler",
      authorizationKey: "tercihler-modulu",
      icon: "mdi-atom",
      altbaslik: false,
      altbasliklar: null,
    },
    {
      id: 3,
      baslikAdi: "Faiz Hesaplayıcı",
      route: ProgramPaths.icrapro + "/hesaplayici",
      authorizationKey: "faiz-hesaplama-modulu",
      icon: "mdi-calculator",
      altbaslik: false,
      altbasliklar: null,
    },
    {
      id: 4,
      baslikAdi: "Toplu İşlemler",
      route: null,
      icon: "mdi-account-voice",
      authorizationKey: "toplu-xml-indir",
      altbaslik: true,
      altbasliklar: [
        {
          id: 1,
          baslikAdi: "Toplu Xml İndirme",
          route: ProgramPaths.icrapro + "/toplu-isler",
          icon: "mdi-download",
          authorizationKey: "toplu-xml-indir",
        },
        {
          id: 2,
          baslikAdi: "Toplu Excel Takip Aç",
          route: ProgramPaths.icrapro + "/toplu-excel",
          icon: "mdi-download",
          authorizationKey: "toplu-excel-takip",
        },
      ],
    },

  ];

  eicraProItems: Array<any> = [
    {
      id: 1,
      baslikAdi: "Takip İşlemleri",
      route: null,
      icon: "mdi-account-voice",
      authorizationKey: "takip-surec-yonetimi",
      altbaslik: true,
      altbasliklar: [
        {
          id: 1,
          baslikAdi: "Takip Hazırla",
          route: ProgramPaths.eicrapro + "/takip-hazirla",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 2,
          baslikAdi: "Takip Aç",
          route: ProgramPaths.eicrapro + "/takip-ac",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 3,
          baslikAdi: "MTS Takip Hazırla",
          route: ProgramPaths.eicrapro + "/mts-takip-hazirla",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 4,
          baslikAdi: "MTS Takip Aç",
          route: ProgramPaths.eicrapro + "/mts-takip-ac",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 5,
          baslikAdi: "Takip Hash Listesi",
          route: ProgramPaths.eicrapro + "/takip-hash-list",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
      ],
    },
    {
      id: 1,
      baslikAdi: "MTS İşlemleri",
      route: null,
      icon: "mdi-office-building",
      authorizationKey: "takip-surec-yonetimi",
      altbaslik: true,
      altbasliklar: [
        {
          id: 1,
          baslikAdi: "MTS Tebligat Gönder",
          route: ProgramPaths.eicrapro + "/mts-tebligat-gonder",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 2,
          baslikAdi: "MTS Ödeme Yap",
          route: ProgramPaths.eicrapro + "/mts-odeme",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
      ],
    },
    {
      id: 3,
      baslikAdi: "İcra İşlemleri",
      route: null,
      icon: "mdi-file-document-multiple-outline",
      authorizationKey: "takip-surec-yonetimi",
      altbaslik: true,
      altbasliklar: [
        {
          id: 1,
          baslikAdi: "Harç / Masraf Ödeme",
          route: ProgramPaths.eicrapro + "/harc-masraf-odeme",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 2,
          baslikAdi: "Toplu Haciz Talebi Gönder",
          route: ProgramPaths.eicrapro + "/toplu-haciz-talebi-gonder",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 3,
          baslikAdi: "Toplu Tebligat Talebi Gönder",
          route: ProgramPaths.eicrapro + "/toplu-tebligat-talebi-gonder",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 4,
          baslikAdi: "Toplu Genel Talep Gönder",
          route: ProgramPaths.eicrapro + "/toplu-genel-talep-gonder",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 5,
          baslikAdi: "Evrak Gönderme",
          route: ProgramPaths.eicrapro + "/evrak-gonder",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 6,
          baslikAdi: "Hazır Evrak Gönder",
          route: ProgramPaths.eicrapro + "/hazir-evrak-gonder",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 7,
          baslikAdi: "Evrak İndir",
          route: ProgramPaths.eicrapro + "/evrak-indir",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 8,
          baslikAdi: "Makbuz İndir",
          route: ProgramPaths.eicrapro + "/makbuz-indir",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
      ],
    },
    {
      id: 4,
      baslikAdi: "Sorgulama",
      route: null,
      icon: "mdi-magnify",
      authorizationKey: "takip-surec-yonetimi",
      altbaslik: true,
      altbasliklar: [
        {
          id: 1,
          baslikAdi: "Takip Sorgulama",
          route: ProgramPaths.eicrapro + "/takip-sorgula",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 2,
          baslikAdi: "Kişi Kurum Sorgulama",
          route: ProgramPaths.eicrapro + "/kisi-kurum-sorgula",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
        {
          id: 3,
          baslikAdi: "Avukat / Vekil Sorgulama",
          route: ProgramPaths.eicrapro + "/vekil-sorgula",
          icon: "mdi-circle-medium",
          authorizationKey: "klasor-modulu",
        },
      ],
    },
    {
      id: 5,
      baslikAdi: "İşlemler",
      route: ProgramPaths.eicrapro + "/islemler",
      icon: "mdi-timer-outline",
      altbaslik: false,
      altbasliklar: null,
      authorizationKey: "klasor-modulu",
    },
    // {
    //   id: 6,
    //   baslikAdi: "Deneme Tahtası",
    //   route: ProgramPaths.eicrapro + "/deneme-tahtasi",
    //   icon: "mdi-test-tube",
    //   altbaslik: false,
    //   altbasliklar: null,
    //   authorizationKey: "klasor-modulu",
    // },
  ];

  davaProItems: Array<any> = [];

  arabulucuProItems: Array<any> = [];

  @Watch('$route', {immediate: true, deep: true})
  onUrlChange() {
    let urunPath = this.$route.path.split('/')[1];
    if (('/' + urunPath) == ProgramPaths.ofispro && this.$helper.licenceIsValid(Product.OFISPRO))
      this.items = [...this.ofisProItems];
    else if (('/' + urunPath) == ProgramPaths.icrapro && this.$helper.licenceIsValid(Product.ICRAPROWEB))
      this.items = [...this.icraProItems];
    else if (('/' + urunPath) == ProgramPaths.eicrapro && this.$helper.licenceIsValid(Product.EICRAPRO))
      this.items = this.eicraProItems;
    else if (('/' + urunPath) == ProgramPaths.davapro && this.$helper.licenceIsValid(Product.DAVAPROWEB))
      this.items = this.davaProItems;
    else if (('/' + urunPath) == ProgramPaths.arabulucupro && this.$helper.licenceIsValid(Product.ARABULUCUPRO))
      this.items = this.arabulucuProItems;
    else
      this.items = [];
  }

  openWhatsapp() {
    this.whatsappDialog = true;
  }
}
